<template>
  <div class="box-body">

    <table-items :data_search="data_search"
                 :relations="relations"
                 component="car-deposit-item"
                 :columns="columns"
                 :api_resource="'/car/' + carId + '/deposits'">
    </table-items>
  </div>
</template>

<script>
import Vue from 'vue';
import TableItems from './../../../../components/pagination/table-items';

Vue.component('car-deposit-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'car-deposits',

  components: {
    TableItems,
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
    data_search: {
      type: String,
      required: false
    }
  },

  data: () => ({
    columns: [
      {
        title: 'ID',
        class: null,
        style: null,
      },
      {
        title: 'Сумма',
        class: null,
        style: null,
      },
      {
        title: 'Клиент',
        class: null,
        style: null,
      },
      {
        title: 'Создал',
        class: null,
        style: null,
      },
      {
        title: 'Дата',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
    ],
    relations: [
      'client',
      'creator',
    ],
  }),
}
</script>

<style lang="scss" scoped>

</style>